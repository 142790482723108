<template>
  <div>
    <Component
      :is="getType(hero)"
      v-for="hero in heros"
      :key="hero.id"
      :data="hero"
    />
  </div>
</template>

<script setup lang="ts">
type Hero = { type: string; id: string }

defineProps<{
  heros: Hero[]
}>()

function getType(hero: Hero) {
  if (!hero.type) {
    console.error('heroLoader: Could not find hero with type of', hero)
    return null
  }

  return hero.type.replaceAll(/_/g, '-')
}
</script>
